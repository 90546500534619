import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

type Props = {
  text?: string;
};

export function Loading(props: Props) {
  const theme = useTheme();

  return (
    <>
      <LinearProgress />
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          paddingBottom: theme.spacing(4),
          paddingTop: theme.spacing(4),
        }}
      >
        <Grid item>
          <Typography variant="h6" component="h2">
            {props.text ?? 'Loading...'}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
