import { useEffect, useState } from 'react';

import { Stripe, loadStripe } from '@stripe/stripe-js';

export default function useStripe() {
  const [stripe, setStripe] = useState<Stripe | undefined>();

  useEffect(() => {
    (async () => {
      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
      );
      if (stripe) {
        setStripe(stripe);
      }
    })().catch(() => console.error('Failed to load Stripe.'));
  }, []);

  return stripe;
}
