import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useRecoilState } from 'recoil';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';

import { addProblem } from '../firebase/FirebaseFunction';

import { problemEditDialogOpenState } from '../Atoms';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function AddProblemDialog() {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(problemEditDialogOpenState);
  const [problem, setProblem] = useState({ title: '', content: '', label: '' });
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      await addProblem({ problem: { ...problem } });
    } catch (err) {
      console.log(err);
    }
    setOpen(false);
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setProblem((prevState) => ({
      ...prevState,
      content: newValue,
    }));
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setProblem((prevState) => ({
      ...prevState,
      title: newValue,
    }));
  };

  const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setProblem((prevState) => ({
      ...prevState,
      label: newValue,
    }));
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              marginLeft: theme.spacing(2),
              flex: 1,
            }}
          >
            Create New Problem
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              handleSave().catch(() =>
                console.error('Failed to save the problem.'),
              );
            }}
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <TextField
            id="outlined-full-width"
            label="Problem Title"
            style={{ margin: 8 }}
            placeholder="problem title..."
            helperText="Give the problem a title..."
            fullWidth
            variant="outlined"
            onChange={handleTitleChange}
          />
          <TextField
            id="outlined-margin-normal"
            label="Label"
            sx={{
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
              width: '30ch',
            }}
            helperText="which category is this problem?"
            margin="normal"
            variant="outlined"
            onChange={handleLabelChange}
          />
          <TextField
            id="outlined-multiline-static"
            label="Problem Content"
            multiline
            style={{ margin: 8 }}
            rows={20}
            fullWidth
            variant="outlined"
            onChange={handleContentChange}
          />
          <ReactMarkdown>{problem?.content}</ReactMarkdown>
        </div>
      </Box>
    </Dialog>
  );
}
