import { Helmet } from 'react-helmet';

import { useTheme } from '@mui/material/styles';

import {
  LanguageTabPanel,
  LanguageTabs,
} from '../../../components/LanguageTabs';

export function AlgoTogetherSuccessStories() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>AlgoTogether Success Stories</title>
        <link
          rel="canonical"
          href="https://techcareer.io/programs/algotogether/stories"
        />
      </Helmet>
      <h2>Chelsea (2020Q2 Program)</h2>
      <LanguageTabs>
        <LanguageTabPanel
          language="en"
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            overflow: 'auto',
          }}
        >
          <p>
            I obtained my bachelor of computer science degree in China. My
            university is not a university from project 211 or project 985 and
            my university is more famous for its liberal art majors not
            engineering majors. Engineering wasn’t my strong suit. I only
            started coding at college. I didn’t have any coding-related
            internship at college. My coding skills weren’t outstanding back
            then. I started practise coding one year into grad school in order
            to prepare for internship recruiting. I also signed up for an
            algorithm class to learn algorithm fundamentals. Then I got my first
            internship at a credit card company in the US.
          </p>
          <p>
            Hoping to refresh my algorithm knowledge before my internship, I
            enrolled in the AlgoTogether program. I heard about the program and
            Coach Cat’s reputation from peers in an coding group. During the
            9-week program, we focused on one topic each week. A list of problem
            sets was provided at the beginning of the week, then we had office
            hour during the week, followed by peer presentation and mock
            interviews at weekend. On top of that, regarding some specific
            topic, coaches would give us lecture. The workload was heavy.
          </p>
          <p>
            Before joining AlgoTogether, I often merely concentrated on solving
            the problems at job interviews. I thought being able to get the
            solution was equivalent to good performance at an interview. I
            couldn’t illustrate my thoughts in an organized and clear manner. At
            AlgoTogether, coaches would comment on our peers’ problem solving
            presentation. Additionally, coaches shared with us the techniques to
            better communicate with the interviewers. Thanks to AlgoTogether, I
            not only understood each question we covered in the homework
            thoroughly, but also acquired effective communication skills for
            real-life interviews.
          </p>
          <p>
            This fall, <strong>I successfully got an offer from Amazon</strong>.
            I’m very grateful for everything I learned from AlgoTogether!
          </p>
        </LanguageTabPanel>
        <LanguageTabPanel
          language="zh"
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            overflow: 'auto',
          }}
        >
          <p>
            我之前在国内的本科学校比较偏向文科，工科不是很强，上大学才第一次接触编程，本科毕业之后专业知识也不是很强，没有任何实习经验。硕士期间研一找实习的时候才开始刷题，报了算法班学习了一些基础的算法知识，第一份美国实习在某信用卡公司。
          </p>
          <p>
            开始实习之前复习了算法知识，在刷题群里看到了小猫老师的AlgoTogether的宣传就报了名。每周都有topic和题目的list，作业量不小，周中有office
            hour，周末是同学做presentation和mock
            interview，有的topic老师会讲lecture。对于我来说项目最大的亮点是每周的presentation和mock都可以得到大厂面试官的点评。以前我面试的时候都是埋头努力做题，把题做出来感觉面试就稳了，讲题的时候freestyle，有几次自己都感觉讲题有点讲得乱七八糟，前言不搭后语的。在AlgoTogether每次的课上每个同学讲题的过程都会得到面试官的点评，其中穿插着一些和面试官交流的技巧，整个program结束后除了把作业里的题目都非常透彻的过了一遍之外，我也学会了怎么在面试的时候能和面试官进行更有效的沟通。
          </p>
          <p>
            今年秋招的时候<strong>拿到了Amazon的offer成功上岸了</strong>
            ，非常感谢AlgoTogether对我的帮助！
          </p>
        </LanguageTabPanel>
      </LanguageTabs>
      <h2>Kenshin (2020 Pilot Program)</h2>
      <LanguageTabs>
        <LanguageTabPanel
          language="en"
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            overflow: 'auto',
          }}
        >
          <p>
            I obtained my bachelor degree overseas, majoring in computer
            science. Currently I’m a master student in computer science at a top
            30 university on the East Coast in the US. I had two BAT internship
            experiences before.
          </p>
          <p>
            I enrolled in the Pilot Program of AlgoTogether, after hearing about
            it from Coach Cat on Zhihu. My objective was to improve my interview
            skills and problem solving skills, and to understand what top topics
            were more popular. I knew the coaches were all seasoned veterans in
            the industry. Learning their perspectives can help me work towards
            my objective more efficiently and effectively.
          </p>
          <p>
            Soon after the program started I realized the coaches were very
            professional and devoted. They were always happy to answer all
            questions. Each week we focused on one topic while the 9-week
            program covered a wide range of topics. Additionally, in the last
            week, the coaches shared with us the most updated OA and interview
            questions at big tech companies. For each topic, there were
            mandatory problems and elective ones. At the first class, the
            coaches would walk us through the topic of the week. They would
            provided an overview of general solution and common coding pattern,
            followed by more details and how to handle different variations.
            Then we had around 5 days to attempt to solved the practice
            problems. At the next class of each week, students would demonstrate
            the mandatory problems in an mock interview format. Coaches would
            provide constructive feedback from an interviewer’s perspective. If
            your heavy workload at school leaves your limited time for this, you
            can still work on two problems every day and internalize the
            knowledge and have good understanding of the weekly topic.
          </p>
          <p>
            After I completed the AlgoTogether program,{' '}
            <strong>
              I successfully passed the interview and obtained an internship
              offer from Amazon. I got a return offer subsequently.
            </strong>{' '}
            At the Amazon interview, I applied the approach I learned from
            AlgoTogether. After the interviewer gave me the prompt, I first took
            initiative to communicate with the interviewer, proposed some
            potential solutions and explained my design choice before I actually
            started coding.
          </p>
          <p>
            I would recommend the AlgoTogether program to whoever preparing for
            an algorithm interviews in the North America. If you have very
            limited time, all you have to do is to take two hours out of your
            schedule each day and follow the AlgoTogether program schedule. You
            will then be able to know how to tackle most popular and most recent
            algorithm interview problems used by different tech companies. If
            you have more time, on top of the weekly 10 questions provide by
            AlgoTogether, you can practise more on Leetcode by using the same
            tags. Once you get to know the solution of one type of problems, you
            can practise more to reinforce your understanding, and improve your
            speed.
          </p>
        </LanguageTabPanel>
        <LanguageTabPanel
          language="zh"
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            overflow: 'auto',
          }}
        >
          <p>
            我现在是就读于北美东部某top30读cs master,
            本科海本cs。之前本科的时候在BAT实习过两次。
          </p>
          <p>
            我参加了AlgoTogether的第一期pilot
            program。当时是从Cat的知乎得知了这个program，报读的目的是希望通过这个program可以提升面试技巧，加强解算法面试题的能力，希望能够从coach那边获取哪些题目是非常高频的，以及哪些考点是非常高频的，希望了解这些从而达到事半功倍的效果。
          </p>
          <p>
            读了以后，我觉得教练很上心水平也很高，有问必答。每周的资料都很有针对性，涵盖面很广。最后一周还有coach自己收集总结的的最新大厂
            OA
            以及面试题，每周都分专题，有必做题和选做题，coach会在每一周的第一节课先对这周的专题进行一下讲解，给出general
            solution和coding
            pattern，会详细讲述遇到这类题应该是一个怎么样的思路，以及遇到变种题如何基于我们的general
            pattern进行修改，然后我们有五天左右的时候把留给我们的题目都完成。在第二节课上，必做题都会由每个人以mock
            interview的方式讲述，coach也会从面试官的角度给每个人有效的建议。个人感觉学校如果workload比较重的话就把这些题完全消化，一天两题就能达到对那一周的知识点的一个比较好的理解。
          </p>
          <p>
            结业之后，
            <strong>
              我成功通过了Amazon的实习面试，最后拿到了return offer
            </strong>
            。在面试过程中，在面试官告诉了我希望我解决什么问题之后，我也是按照coach所讲的那样，在动手之前积极跟面试官沟通有哪些potential
            solution以及我的design choice。
          </p>
          <p>
            我觉得这个项目适合所有在北美面试的同学，如果你的课余时间不是很多的话，你只需要直接follow这个program的schedule，每天挤出两个小时，你就能掌握算法面试的高频题以及最近公司爱出的热点题常见题和相应的解法。如果你的课余时间比较充足的话，这个项目也能给你提供一个很好的方向，你在把每周10道必做题和10道选做题完全消化完之后再去找相同tag的题目去刷题也是得心应手，因为你已经掌握了这一类题的解法，剩下的只是继续加固理解和锻炼手速以及增长见识。
          </p>
        </LanguageTabPanel>
      </LanguageTabs>
      <h2>东海岸打工人 (2020 Pilot Program)</h2>
      <LanguageTabs>
        <LanguageTabPanel
          language="en"
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            overflow: 'auto',
          }}
        >
          <p>
            My background: I majored in Optoelectronic Information Science and
            Engineering at a mid-tier “Project 985” university in China. To
            explore more career development opportunities, I came to the US and
            pursued a master degree in computer science. Now I’m working on the
            East Coast.
          </p>
          <p>
            I was a student of the 2020 Pilot Program of AlgoTogether. I learned
            about the program from Coach Cat. I already got my job offer before
            the program. Still, I knew I had to improve my systematic problem
            solving and efficient communication skills for future job
            interviews. My interviewers are usually my coworkers if I get an
            offer, so it’s important to learn how to better communicate with
            them. Meanwhile, the AlgoTogether coach was a seasoned engineer at
            Airbnb, I’ve learned a ton from him, both coding and working in the
            real life.
          </p>
          <p>
            I completed the AlgoTogether program 6 months ago. Looking back now,
            my major takeaways are the following:
          </p>
          <ol>
            <li>
              The question list is carefully designed and the course is well
              structured. If a student attends all classes and diligently
              practicing modelling for each question, he/she will be able to
              solve most problems on Leetcode, except for some uncommon topics
              or problems that involve complicated math.
            </li>
            <li>
              Coaches are very experienced. They were able to address most
              issues encountered at work in reality, while providing a lot of
              good suggestions on how to improve and optimize one’s coding
              style. For example, they pointed out we should avoid using “magic
              number” and limit the length of our codes. More importantly, the
              provided a new perspective as my future coworkers. They made me
              realized that my codes would be intended for my coworker’s use.
              Therefore, I should ensure my algorithms and codes are clean and
              clear so as to make communication with coworkers easier.
            </li>
            <li>
              All my classmates were hardworking and diligent. We learned from
              each other at the weekly mock presentation. There were a lot of
              wonderful thoughts which were not covered by discussion on
              Leetcode. Coaches were very patient and willing to spend extra
              time as long as that facilitated class discussion.
            </li>
            <li>
              Each week we had around 10 questions, which took a reasonable
              amount of time to finish. Once I understood the mock questions, my
              performance at the real interview improved. Being able to
              logically solving a problem with no error in coding doesn’t
              necessarily mean being able to illustrating the code clearly. The
              latter requires a huge amount of practice. Since an interviewee is
              very likely to become the interviewer’s future coworkers, the
              interviewer is therefore looking for a candidate who they can
              easily and efficiently communicate with.
            </li>
          </ol>
        </LanguageTabPanel>
        <LanguageTabPanel
          language="zh"
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            overflow: 'auto',
          }}
        >
          <p>
            背景：我本科在国内中流985读的光电专业，感觉发展机会不大，就去美国东海岸读了计算机硕士,
            目前在东海岸小公司上班。
          </p>
          <p>
            我参加了AlgoTogether的2020 pilot program，
            当时和Cat在一个群里得知了这个program，虽然当时我已经找到工作，但是我很清楚找到工作是个概率问题，对于如何系统性解决大部分面试题以及和面试官（我将来的同事）进行沟通等方面我并没有做到合格，另外教练是Airbnb工作经验丰富的工程师，我能从他身上学很多刷题以外，工作以内的东西。
          </p>
          <p>现在项目结束了已经有半年，回头看这个课程目的都已经达到了。</p>
          <ol>
            <li>
              题目归纳的很有条理，基本上跟着项目走下来，认真建立解题模型，leetcode上大部分题目都有思路了（除了一些数学题还有一些用到小众技巧的题目）。
            </li>
            <li>
              教练业界经验很丰富，回答了很多关于实际工作的问题，也给出了很多代码风格如何优化的建议。比如magic
              number,
              方法不应该超过多少行。最重要的还是给出了“同事视角”，即你的代码是给你同事看的，要尽可能的降低沟通成本，一般题目追求算法复杂度低，同时尽可能做到clean
              clear。
            </li>
            <li>
              同学都很认真，每周一次mock的时候听同学讲也收获挺多的。很多题目的想法是leetcode
              discussion里面也没有的。教练也非常有耐心，有一次周二晚上讲一个比较难的题目，一直讨论了快半小时，我这边东边都11点多了。
            </li>
            <li>
              项目每周大概十个题，要都弄懂还是要花点时间的。做mock对真实面试帮助很大，需要自己思路清晰，代码能跑到把代码讲清楚这之间存在不小的距离，需要大量练习，面试官很可能是你将来的同事，希望和一个容易沟通的人一起上班。
            </li>
          </ol>
        </LanguageTabPanel>
      </LanguageTabs>
      <h2>How can I become a successful student like them?</h2>
      <p>
        Please pick a program from below and click the &quot;purchase&quot;
        button to start. We will contact you through email after successful
        payment.
      </p>
    </>
  );
}
