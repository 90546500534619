import React, { Suspense } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { default as SyntaxHighlighter } from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {
  currentProblemIdState,
  currentSolutionState,
  solutionEditDialogOpenState,
} from '../../Atoms';
import { EditSolutionDialog } from '../../components/EditSolutionDialog';
import { Loading } from '../../components/Loading';

export function Solution() {
  const { id } = useParams();
  if (id === undefined) {
    throw new Error('Solution component requires an id param');
  }
  return (
    <div>
      <Link to={`/problems/${id}/problem`}>Go To Problem</Link>
      <Link to={`/problems/${id}/solution`}>Go To Solution</Link>
      <Link to={`/problems/${id}/submissions`}>Go To Submissions</Link>
      <Link to={`/problems`}>Go Back to Problems</Link>
      <Suspense fallback={<Loading text="Loading..." />}>
        <SolutionContent id={id} />
      </Suspense>
    </div>
  );
}

function SolutionContent({ id }: { id: string }) {
  const [problemId, setProblemId] = useRecoilState(currentProblemIdState);

  // in solution page, set problemIdState with value from path
  if (problemId === undefined || problemId === null || problemId !== id) {
    setProblemId(() => id);
  }

  const solution = useRecoilValue(currentSolutionState);
  const content = solution.content;

  const setOpen = useSetRecoilState(solutionEditDialogOpenState);

  const components = {
    code({
      inline,
      className,
      children,
      ...props
    }: {
      inline?: boolean;
      className?: string;
      children: React.ReactNode;
    }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={github}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props} />
      );
    },
  };

  return (
    <Box
      component="div"
      sx={{
        margin: 20,
      }}
    >
      <Box
        component="div"
        sx={{
          fontSize: 15,
          width: '100ch',
        }}
      >
        <ReactMarkdown components={components}>{content}</ReactMarkdown>
      </Box>
      <EditSolutionDialog data={{ id, solution }} />
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        color="primary"
      >
        Edit Solution
      </Button>
    </Box>
  );
}
