import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FirebaseAnalytics } from '../firebase/FirebaseAnalytics';
import { EventParams, logEvent, setCurrentScreen } from 'firebase/analytics';

export function Analytics() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setCurrentScreen(FirebaseAnalytics, location.pathname);
    logEvent(FirebaseAnalytics, 'screen_view', {
      app_name: window.location.origin,
      firebase_screen: `${location.pathname}${location.search}`,
      firebase_screen_class: 'Analytics',
    });
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const eventName = searchParams.get('event_name');
    const eventParams = JSON.parse(
      searchParams.get('event_params') ?? 'null',
    ) as EventParams;
    if (eventName) {
      logEvent(FirebaseAnalytics, eventName, { ...eventParams });
      searchParams.delete('event_name');
      searchParams.delete('event_params');
      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [navigate, location]);

  return null;
}
