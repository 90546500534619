import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { FirebaseAnalytics } from '../firebase/FirebaseAnalytics';
import { getStripePrice } from '../firebase/FirebaseFunction';
import { EventParams, logEvent } from 'firebase/analytics';

import { GetStripePrice } from '../../functions/src/schemas/GetStripePrice';

export default function useCheckout(item: string) {
  const location = useLocation();
  const navigate = useNavigate();
  const [ready, setReady] = useState(false);
  const [price, setPrice] = useState<GetStripePrice.Response | null>(null);

  useEffect(() => {
    (async () => {
      const response = await getStripePrice({ item });
      setPrice(response.data);
      setReady(true);
    })().catch(() => console.error(`Failed to fetch the price for ${item}.`));
  }, [item]);

  const path = useMemo(() => {
    const path = `${location.pathname}${location.search}`;
    return `/checkout?item=${encodeURIComponent(
      item,
    )}&path=${encodeURIComponent(path)}`;
  }, [item, location]);

  const click = useMemo(
    () => (event: React.UIEvent) => {
      if (!ready || !price) {
        return;
      }
      const eventParams = price
        ? ({
            currency: price.currency,
            value: price.unit_amount ?? 0 / 100,
          } as EventParams)
        : null;
      logEvent(FirebaseAnalytics, 'add_to_cart', {
        ...eventParams,
      });
      navigate(path, { state: { eventParams } });
      event.preventDefault();
    },
    [ready, price, navigate, path],
  );

  return {
    ready,
    price,
    path,
    click,
  };
}
