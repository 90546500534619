import { useRecoilState } from 'recoil';

import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';

import CloseIcon from '@mui/icons-material/Close';

import {
  contentUpdateBarMessageState,
  contentUpdateBarOpenState,
} from '../Atoms';

export function ContentUpdateBar() {
  const [open, setOpen] = useRecoilState(contentUpdateBarOpenState);
  const [message, setMessage] = useRecoilState(contentUpdateBarMessageState);

  const handleClose = () => {
    // clean up currentEditingSubmission
    setMessage(undefined);
    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={Slide}
      open={open}
      message={message}
      action={
        <IconButton color="inherit" onClick={handleClose} size="large">
          <CloseIcon />
        </IconButton>
      }
      onClose={handleClose}
    ></Snackbar>
  );
}
