import { useRecoilState } from 'recoil';

import Button from '@mui/material/Button';

import { currentProblemFiltersState } from '../Atoms';
import { ALL_PROBLEM_FILTERS } from '../const/AlgoLibraryConst';

export function AllProblemsFilterButton() {
  const [currentProblemFilters, setCurrentProblemFilters] = useRecoilState(
    currentProblemFiltersState,
  );

  const clickAllProblemsButton = () => {
    // if allproblem state is true, update problem filter state to none
    if (currentProblemFilters.length !== 0) {
      setCurrentProblemFilters([]);
    }
    // if allproblem state is false, update problem filter state to all filters
    else {
      setCurrentProblemFilters(ALL_PROBLEM_FILTERS);
    }
  };

  return <Button onClick={clickAllProblemsButton}>All Problems</Button>;
}

export function FilterButton({ filter }: { filter: string }) {
  const [currentProblemFilters, setCurrentProblemFilters] = useRecoilState(
    currentProblemFiltersState,
  );

  const clickCurrentProblemFilter = (item: string) => {
    // if currentProblemFiltersState contains current problem filter, remove it
    if (currentProblemFilters.includes(item)) {
      setCurrentProblemFilters(
        currentProblemFilters.filter((f) => {
          return f !== item;
        }),
      );
    }
    // if currentProblemFiltersState !contains current problem filter, add it
    else {
      setCurrentProblemFilters([...currentProblemFilters, item]);
    }
  };

  return (
    <Button onClick={() => clickCurrentProblemFilter(filter)}>{filter}</Button>
  );
}
