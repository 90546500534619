import { Helmet } from 'react-helmet';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import NewReleasesIcon from '@mui/icons-material/NewReleases';

export function Home() {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://techcareer.io/" />
      </Helmet>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          marginTop: theme.spacing(4),
        }}
      >
        <Grid item>
          <NewReleasesIcon
            sx={{
              fontSize: 72,
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" component="h2">
            Our website will be released soon!
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
