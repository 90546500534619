import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';

import { FirebaseAuth } from '../firebase/FirebaseAuth';

import { navigationDrawerOpenState } from '../Atoms';
import useFirebaseUser from '../hooks/useFirebaseUser';

const DEFAULT_TITLE = 'Tech Career';

export function NavigationBar({
  title,
  children,
}: {
  title?: React.ReactNode;
  children?: React.ReactNode;
}) {
  const navigate = useNavigate();
  const setNavigationDrawerOpen = useSetRecoilState(navigationDrawerOpenState);
  const { ready, user } = useFirebaseUser();
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement>();

  const avatar = useMemo(() => {
    if (!ready) {
      return <CircularProgress color="inherit" />;
    }

    const displayName = user?.displayName || undefined;
    const photoURL = user?.photoURL || undefined;
    if (user) {
      if (displayName && !photoURL) {
        return (
          <Avatar alt={displayName}>
            {displayName
              .toUpperCase()
              .split(' ')
              .map((word) => word[0])
              .join('')}
          </Avatar>
        );
      } else {
        return <Avatar alt={displayName} src={photoURL} />;
      }
    } else {
      return <AccountCircleIcon />;
    }
  }, [ready, user]);

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          color="inherit"
          onClick={() => {
            setNavigationDrawerOpen(true);
          }}
          edge="start"
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            flexGrow: 1,
          }}
          noWrap
        >
          {title ?? DEFAULT_TITLE}
        </Typography>
        {user ? (
          <IconButton
            color="inherit"
            onClick={(event) => {
              setMenuAnchor(event.currentTarget);
            }}
            size="large"
          >
            {avatar}
          </IconButton>
        ) : (
          <Button
            color="inherit"
            onClick={(event) => {
              setMenuAnchor(event.currentTarget);
            }}
          >
            Login
          </Button>
        )}

        <Menu
          anchorEl={menuAnchor}
          keepMounted
          open={menuAnchor !== undefined}
          onClose={() => {
            setMenuAnchor(undefined);
          }}
        >
          {user !== null
            ? [
                <MenuItem
                  key="billing"
                  onClick={() => {
                    setMenuAnchor(undefined);
                    navigate('/billing');
                  }}
                >
                  Billing
                </MenuItem>,
                <MenuItem
                  key="logout"
                  onClick={() => {
                    setMenuAnchor(undefined);
                    FirebaseAuth.logout().catch(() =>
                      console.error('Failed to logout.'),
                    );
                  }}
                >
                  Logout
                </MenuItem>,
              ]
            : [
                <MenuItem
                  key="loginWithGoogle"
                  onClick={() => {
                    setMenuAnchor(undefined);
                    FirebaseAuth.loginWithGoogle().catch(() =>
                      console.error('Failed to login with Google.'),
                    );
                  }}
                >
                  Login with Google
                </MenuItem>,
                <MenuItem
                  key="loginWithGitHub"
                  onClick={() => {
                    setMenuAnchor(undefined);
                    FirebaseAuth.loginWithGitHub().catch(() =>
                      console.error('Failed to login with GitHub.'),
                    );
                  }}
                >
                  Login with Github
                </MenuItem>,
              ]}
        </Menu>
      </Toolbar>
      {children}
    </AppBar>
  );
}
