import { Link } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { currentFilteredProblemsValue, currentProblemIdState } from '../Atoms';

export function ProblemList() {
  const theme = useTheme();
  const [problemId, setProblemId] = useRecoilState(currentProblemIdState);

  // in problem list page, set problemIdState to null
  if (problemId !== null) {
    setProblemId(() => null);
  }

  const currentFilteredProblems = useRecoilValue(currentFilteredProblemsValue);

  return (
    <Grid>
      {Object.entries(currentFilteredProblems).map(([id, problem]) => (
        <Card
          sx={{
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
          }}
          key={id}
        >
          <CardContent>
            <Link to={`/problems/${id}/problem`}>Go To Problem</Link>
            <Link to={`/problems/${id}/solution`}>Go To Solution</Link>
            <Link to={`/problems/${id}/submissions`}>Go To Submissions</Link>
            <Typography>{id}</Typography>
            <Typography>{problem.title}</Typography>
            <Typography>{problem.content}</Typography>
            <Typography>{problem.label}</Typography>
          </CardContent>
        </Card>
      ))}
    </Grid>
  );
}
