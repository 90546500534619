import firebaseConfig from './firebaseConfig.json';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import type { FirebaseOptions } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';

const firebaseApp = initializeApp(firebaseConfig as FirebaseOptions);

export const Analytics = getAnalytics(firebaseApp);
export const Auth = getAuth(firebaseApp);
export const Functions = getFunctions(firebaseApp);
