import { useRecoilState } from 'recoil';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';

import HomeIcon from '@mui/icons-material/Home';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import WorkIcon from '@mui/icons-material/Work';

import { navigationDrawerOpenState } from '../Atoms';
import { LinkListItem } from './LinkListItem';

export function NavigationDrawer() {
  const theme = useTheme();
  const [navigationDrawerOpen, setNavigationDrawerOpen] = useRecoilState(
    navigationDrawerOpenState,
  );

  return (
    <Drawer
      anchor="left"
      sx={{
        width: 250,
      }}
      open={navigationDrawerOpen}
      onClose={() => {
        setNavigationDrawerOpen(false);
      }}
    >
      <List
        sx={{
          width: 250,
        }}
      >
        <LinkListItem
          to="/"
          onClick={() => {
            setNavigationDrawerOpen(false);
          }}
        >
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Tech Career" />
        </LinkListItem>
        <ListItem>
          <ListItemIcon>
            <LibraryBooksIcon />
          </ListItemIcon>
          <ListItemText primary="Programs" />
        </ListItem>
        <List
          sx={{
            padding: 0,
            '& > *': {
              paddingLeft: theme.spacing(4),
            },
          }}
        >
          <LinkListItem
            to="/programs/algotogether"
            onClick={() => {
              setNavigationDrawerOpen(false);
            }}
          >
            <ListItemIcon>
              <MenuBookIcon />
            </ListItemIcon>
            <ListItemText primary="AlgoTogether" />
          </LinkListItem>
        </List>
        <LinkListItem
          to="/jobs"
          onClick={() => {
            setNavigationDrawerOpen(false);
          }}
        >
          <ListItemIcon>
            <WorkIcon />
          </ListItemIcon>
          <ListItemText primary="Jobs" />
        </LinkListItem>
      </List>
    </Drawer>
  );
}
