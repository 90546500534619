import React, { Suspense, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { default as SyntaxHighlighter } from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { createSubmission } from '../../firebase/FirebaseFunction';

import {
  currentProblemIdState,
  currentProblemState,
  currentSubmissionsState,
  problemEditDialogOpenState,
} from '../../Atoms';
import { EditProblemDialog } from '../../components/EditProblemDialog';
import { Loading } from '../../components/Loading';

export function Problem() {
  const { id } = useParams();
  if (id === undefined) {
    throw new Error('Problem component requires an id param');
  }
  return (
    <div>
      <Link to={`/problems/${id}/problem`}>Go To Problem</Link>
      <Link to={`/problems/${id}/solution`}>Go To Solution</Link>
      <Link to={`/problems/${id}/submissions`}>Go To Submissions</Link>
      <Link to={`/problems`}>Go Back to Problems</Link>
      <Suspense fallback={<Loading text="Loading..." />}>
        <ProblemContent id={id} />
      </Suspense>
    </div>
  );
}

function ProblemContent({ id }: { id: string }) {
  const [problemId, setProblemId] = useRecoilState(currentProblemIdState);

  // in problem page, set problemIdState with value from path
  if (problemId === undefined || problemId === null || problemId !== id) {
    setProblemId(() => id);
  }

  const setOpen = useSetRecoilState(problemEditDialogOpenState);
  const invalidateSubmissions = useResetRecoilState(currentSubmissionsState);

  const [submissionContent, setSubmissionContent] = useState<string>();

  const handleSubmitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSubmissionContent(newValue);
  };

  const handleSubmit = async () => {
    if (!problemId || !submissionContent) {
      return;
    }
    try {
      await createSubmission({
        submission: { problemId, content: submissionContent },
      });
    } catch (err) {
      console.log(err);
    } finally {
      invalidateSubmissions();
      setSubmissionContent(undefined);
    }
  };

  const components = {
    code({
      inline,
      className,
      children,
      ...props
    }: {
      inline?: boolean;
      className?: string;
      children: React.ReactNode;
    }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={github}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props} />
      );
    },
  };

  // get current problem from currentProblemValue selector
  const problem = useRecoilValue(currentProblemState);
  const content = problem.content;

  return (
    <Box
      component="div"
      sx={{
        margin: 20,
      }}
    >
      <Box
        component="div"
        sx={{
          fontSize: 15,
          width: '100ch',
        }}
      >
        <ReactMarkdown components={components}>{content || ''}</ReactMarkdown>
      </Box>
      <EditProblemDialog data={{ id, problem }} />
      <Button
        onClick={() => {
          setOpen(true);
        }}
        variant="contained"
        color="primary"
      >
        Edit Problem
      </Button>
      <Box
        component="div"
        sx={{
          marginLeft: 1,
          marginRight: 1,
          width: '100ch',
        }}
      >
        <TextField
          id="outlined-multiline-static"
          label="Your Submission"
          multiline
          style={{ marginTop: 20 }}
          rows={10}
          fullWidth
          variant="outlined"
          placeholder="write up your submission in Markdown format"
          onChange={handleSubmitChange}
        />
      </Box>
      <Button
        style={{ marginTop: 20 }}
        onClick={() => {
          handleSubmit().catch(() =>
            console.error('Failed to save the problem.'),
          );
        }}
        variant="contained"
        color="primary"
      >
        Submit
      </Button>
      <Box
        component="div"
        sx={{
          fontSize: 15,
          width: '100ch',
        }}
      >
        <ReactMarkdown components={components}>
          {submissionContent || ''}
        </ReactMarkdown>
      </Box>
    </Box>
  );
}
