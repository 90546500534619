import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import LockIcon from '@mui/icons-material/Lock';

import { FirebaseAuth } from '../firebase/FirebaseAuth';

import { Loading } from '../components/Loading';
import useFirebaseUser from '../hooks/useFirebaseUser';

export function Login() {
  const theme = useTheme();
  const location = useLocation();
  const { ready, user } = useFirebaseUser();
  const path = useMemo(
    () => new URLSearchParams(location.search).get('path'),
    [location],
  );

  if (!ready) {
    return <Loading />;
  }

  if (user) {
    return <Navigate to={path ?? '/'} replace={true} />;
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          marginTop: theme.spacing(4),
        }}
      >
        <Grid item>
          <LockIcon
            sx={{
              fontSize: 72,
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" component="h2">
            This page requires login.
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              FirebaseAuth.loginWithGoogle().catch(() =>
                console.error('Failed to launch login with Google'),
              );
            }}
          >
            Login with Google
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              FirebaseAuth.loginWithGitHub().catch(() =>
                console.error('Failed to launch login with GitHub'),
              );
            }}
          >
            Login with GitHub
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
