import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

export function CheckoutBar() {
  const location = useLocation();
  const [dismissed, setDismissed] = useState(false);
  const message = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('checkout_status');
    if (status === 'success') {
      return <Typography component="span">Payment succeeded.</Typography>;
    } else if (status === 'cancel') {
      return <Typography component="span">Payment canceled.</Typography>;
    } else {
      return null;
    }
  }, [location]);

  const close = () => {
    setDismissed(true);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      TransitionComponent={Slide}
      open={message !== null && !dismissed}
      message={message}
      action={
        <IconButton color="inherit" onClick={close} size="large">
          <CloseIcon />
        </IconButton>
      }
      onClose={close}
    ></Snackbar>
  );
}
