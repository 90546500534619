import { Helmet } from 'react-helmet';

import Box from '@mui/material/Box';

export function AlgoTogetherPreview() {
  return (
    <>
      <Helmet>
        <title>AlgoTogether Free Study Material</title>
        <link
          rel="canonical"
          href="https://techcareer.io/programs/algotogether/preview"
        />
      </Helmet>
      <h2>What study materials are available for free?</h2>
      <p>
        We provide <strong>two lecture videos</strong> so you can get a taste of
        our lecture style. We also give you two algorithm problems with{' '}
        <strong>detailed thinking process</strong>, multiple solutions and
        commont mistakes. They are from the problem library that we are building
        together.
      </p>
      <h2>Video 1: Understanding the Interview</h2>
      <Box
        component="div"
        sx={{
          height: 0,
          overflow: 'hidden',
          paddingBottom: '56.25%',
          position: 'relative',
          '& iframe': {
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          },
        }}
      >
        <iframe
          width="560"
          height="315"
          title="Video 1: Understanding the Interview"
          src="https://www.youtube.com/embed/xAt4yosCtzg"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
      <h2>Video 2: Recognizing BFS and DFS</h2>
      <Box
        component="div"
        sx={{
          height: 0,
          overflow: 'hidden',
          paddingBottom: '56.25%',
          position: 'relative',
          '& iframe': {
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
          },
        }}
      >
        <iframe
          width="560"
          height="315"
          title="Video 2: Recognizing BFS and DFS"
          src="https://www.youtube.com/embed/BOt9_PU-Oqo"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
      <h2>Problem 1: Binary Tree Level Order Traversal</h2>
      <iframe
        className="scribd_iframe_embed"
        title="Binary Tree Level Order Traversal.pdf"
        src="https://www.scribd.com/embeds/481389342/content?start_page=1&view_mode=scroll&access_key=key-hy9EdJhEGhrva8vYVhmu"
        data-auto-height="true"
        data-aspect-ratio="0.7729220222793488"
        scrolling="no"
        width="100%"
        height="600"
        frameBorder="0"
      ></iframe>
      <h2>Problem 2: Coin Change</h2>
      <iframe
        className="scribd_iframe_embed"
        title="Coin Change.pdf"
        src="https://www.scribd.com/embeds/481389917/content?start_page=1&view_mode=scroll&access_key=key-0IWdyFYQAiUOSSS8gK44"
        data-auto-height="true"
        data-aspect-ratio="0.7729220222793488"
        scrolling="no"
        width="100%"
        height="600"
        frameBorder="0"
      ></iframe>
      <h2>Can I ask more questions before signing up?</h2>
      <p>
        If you have questions after checking out the free study materials you
        can email us by clicking the &quot;email inquiry&quot; button at the
        bottom of the page. We will answer your email as quickly as possible.
      </p>
      <h2>How do I sign up and pay for the full program?</h2>
      <p>
        Please pick a program from below and click the &quot;purchase&quot;
        button to start. We will contact you through email after successful
        payment.
      </p>
    </>
  );
}
