import { logEvent } from 'firebase/analytics';
import {
  GithubAuthProvider,
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

import { Auth } from './Firebase';
import { FirebaseAnalytics } from './FirebaseAnalytics';

export const FirebaseAuth = {
  async loginWithGoogle() {
    try {
      const userCredential = await signInWithPopup(
        Auth,
        new GoogleAuthProvider(),
      );
      if (getAdditionalUserInfo(userCredential)?.isNewUser) {
        logEvent(FirebaseAnalytics, 'sign_up', { method: 'Google' });
      }
      logEvent(FirebaseAnalytics, 'login', { method: 'Google' });
      return userCredential;
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  },

  async loginWithGitHub() {
    try {
      const userCredential = await signInWithPopup(
        Auth,
        new GithubAuthProvider(),
      );
      if (getAdditionalUserInfo(userCredential)?.isNewUser) {
        logEvent(FirebaseAnalytics, 'sign_up', { method: 'GitHub' });
      }
      logEvent(FirebaseAnalytics, 'login', { method: 'GitHub' });
      return userCredential;
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        alert(error.message);
      }
    }
  },

  async logout() {
    return signOut(Auth);
  },
};
