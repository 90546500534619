import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';

export function LinkListItem(
  props: React.ComponentProps<typeof RouterLink> &
    React.ComponentProps<typeof ListItemButton>,
) {
  return <ListItemButton {...props} component={RouterLink} />;
}
