import { Helmet } from 'react-helmet';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import ErrorIcon from '@mui/icons-material/Error';

export function NotFound() {
  const theme = useTheme();
  return (
    <Container
      fixed
      sx={{
        marginTop: 4,
      }}
    >
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{
          marginTop: theme.spacing(4),
        }}
      >
        <Grid item>
          <ErrorIcon
            sx={{
              fontSize: 72,
            }}
          />
        </Grid>
        <Grid item>
          <Typography variant="h6" component="h2">
            Content not found.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
