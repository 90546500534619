import { useRecoilValue } from 'recoil';

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useTheme } from '@mui/material/styles';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { jobsDataValue } from '../Atoms';

export function JobsTable() {
  const theme = useTheme();
  const data = useRecoilValue(jobsDataValue);

  return (
    <TableContainer
      sx={{
        bottom: 0,
        left: 0,
        position: 'absolute',
        right: 0,
        top: theme.spacing(8),
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell>SWE Intern</TableCell>
            <TableCell>SWE NewGrad</TableCell>
            <TableCell>SWE Senior</TableCell>
            <TableCell>SWE Manager</TableCell>
            <TableCell>DS Intern</TableCell>
            <TableCell>DS NewGrad</TableCell>
            <TableCell>DS Senior</TableCell>
            <TableCell>DS Manager</TableCell>
            <TableCell>PM Intern</TableCell>
            <TableCell>PM NewGrad</TableCell>
            <TableCell>PM Senior</TableCell>
            <TableCell>PM Manager</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data).map((company) => (
            <TableRow key={company}>
              <TableCell component="th" scope="row">
                {data[company]?.url ? (
                  <Link
                    href={data[company].url}
                    target="_blank"
                    rel="noreferrer"
                    underline="hover"
                  >
                    {company}
                  </Link>
                ) : (
                  company
                )}
              </TableCell>
              <TableCell>
                {data[company].jobs?.softwareEngineer?.intern?.available ? (
                  data[company].jobs?.softwareEngineer?.intern?.url ? (
                    <Link
                      href={data[company].jobs?.softwareEngineer?.intern?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.softwareEngineer?.newGrad?.available ? (
                  data[company].jobs?.softwareEngineer?.newGrad?.url ? (
                    <Link
                      href={data[company].jobs?.softwareEngineer?.newGrad?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.softwareEngineer?.senior?.available ? (
                  data[company].jobs?.softwareEngineer?.senior?.url ? (
                    <Link
                      href={data[company].jobs?.softwareEngineer?.senior?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.softwareEngineer?.manager?.available ? (
                  data[company].jobs?.softwareEngineer?.manager?.url ? (
                    <Link
                      href={data[company].jobs?.softwareEngineer?.manager?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.dataScientist?.intern?.available ? (
                  data[company].jobs?.dataScientist?.intern?.url ? (
                    <Link
                      href={data[company].jobs?.dataScientist?.intern?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.dataScientist?.newGrad?.available ? (
                  data[company].jobs?.dataScientist?.newGrad?.url ? (
                    <Link
                      href={data[company].jobs?.dataScientist?.newGrad?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.dataScientist?.senior?.available ? (
                  data[company].jobs?.dataScientist?.senior?.url ? (
                    <Link
                      href={data[company].jobs?.dataScientist?.senior?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.dataScientist?.manager?.available ? (
                  data[company].jobs?.dataScientist?.manager?.url ? (
                    <Link
                      href={data[company].jobs?.dataScientist?.manager?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.productManager?.intern?.available ? (
                  data[company].jobs?.productManager?.intern?.url ? (
                    <Link
                      href={data[company].jobs?.productManager?.intern?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.productManager?.newGrad?.available ? (
                  data[company].jobs?.productManager?.newGrad?.url ? (
                    <Link
                      href={data[company].jobs?.productManager?.newGrad?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.productManager?.senior?.available ? (
                  data[company].jobs?.productManager?.senior?.url ? (
                    <Link
                      href={data[company].jobs?.productManager?.senior?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
              <TableCell>
                {data[company].jobs?.productManager?.manager?.available ? (
                  data[company].jobs?.productManager?.manager?.url ? (
                    <Link
                      href={data[company].jobs?.productManager?.manager?.url}
                      target="_blank"
                      rel="noreferrer"
                      underline="hover"
                    >
                      <CheckCircleIcon />
                    </Link>
                  ) : (
                    <CheckCircleIcon />
                  )
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
