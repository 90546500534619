import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { blue, orange } from '@mui/material/colors';
import {
  StyledEngineProvider,
  ThemeProvider,
  adaptV4Theme,
  createTheme,
} from '@mui/material/styles';

import './App.css';
import { AppRoutes } from './AppRoutes';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme(
  adaptV4Theme({
    palette: {
      primary: blue,
      secondary: orange,
    },
  }),
);

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RecoilRoot>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </RecoilRoot>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
