import { Suspense } from 'react';
import { Helmet } from 'react-helmet';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import { JobsTable } from '../components/JobsTable';
import { Loading } from '../components/Loading';

export function Jobs() {
  const theme = useTheme();
  return (
    <Container
      fixed
      sx={{
        bottom: theme.spacing(4),
        left: 0,
        position: 'absolute',
        right: 0,
        top: theme.spacing(12),
      }}
    >
      <Helmet>
        <title>Jobs</title>
        <link rel="canonical" href="https://techcareer.io/jobs" />
      </Helmet>
      <Paper
        sx={{
          height: '100%',
          position: 'relative',
          width: '100%',
        }}
      >
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              flexGrow: 1,
            }}
            noWrap
          >
            Jobs
          </Typography>
          <Button
            component="a"
            target="_blank"
            rel="noreferrer"
            startIcon={<PlaylistAddIcon />}
            href="https://github.com/techcareerio/tech-jobs"
          >
            Contribute Data
          </Button>
        </Toolbar>
        <Suspense fallback={<Loading text="Loading jobs data..." />}>
          <JobsTable />
        </Suspense>
      </Paper>
    </Container>
  );
}
