import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { getStripeBillingPortalUrl } from '../firebase/FirebaseFunction';

import { Loading } from '../components/Loading';
import useFirebaseUser from '../hooks/useFirebaseUser';

export function Billing() {
  const { ready, user } = useFirebaseUser();

  useEffect(() => {
    (async () => {
      const response = await getStripeBillingPortalUrl();
      window.location.href = response.data.url;
    })().catch(() => console.error('Failed to fetch billing portal URL.'));
  }, [ready, user]);

  return (
    <>
      <Helmet>
        <title>Billing</title>
      </Helmet>
      <Loading text="Preparing your billing information..." />
    </>
  );
}
