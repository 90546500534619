import { httpsCallable } from 'firebase/functions';

import { AddProblem } from '../../functions/src/schemas/AddProblem';
import { CreateSubmission } from '../../functions/src/schemas/CreateSubmission';
import { DeleteSubmissionById } from '../../functions/src/schemas/DeleteSubmissionById';
import { GetProblemById } from '../../functions/src/schemas/GetProblemById';
import { GetProblems } from '../../functions/src/schemas/GetProblems';
import { GetSolutionById } from '../../functions/src/schemas/GetSolutionById';
import { GetStripeBillingPortalUrl } from '../../functions/src/schemas/GetStripeBillingPortalUrl';
import { GetStripeCheckoutSession } from '../../functions/src/schemas/GetStripeCheckoutSession';
import { GetStripePrice } from '../../functions/src/schemas/GetStripePrice';
import { GetSubmissionsById } from '../../functions/src/schemas/GetSubmissionsById';
import { UpdateProblemById } from '../../functions/src/schemas/UpdateProblemById';
import { UpdateSolutionById } from '../../functions/src/schemas/UpdateSolutionById';
import { UpdateSubmissionById } from '../../functions/src/schemas/UpdateSubmissionById';
import { Functions } from './Firebase';

export const FirebaseFunction = <Request, Response>(name: string) => {
  return httpsCallable<Request, Response>(Functions, name);
};

export const getStripeBillingPortalUrl = FirebaseFunction<
  GetStripeBillingPortalUrl.Request,
  GetStripeBillingPortalUrl.Response
>('getStripeBillingPortalUrl');

export const getStripeCheckoutSession = FirebaseFunction<
  GetStripeCheckoutSession.Request,
  GetStripeCheckoutSession.Response
>('getStripeCheckoutSession');

export const getStripePrice = FirebaseFunction<
  GetStripePrice.Request,
  GetStripePrice.Response
>('getStripePrice');

export const getProblems = FirebaseFunction<
  GetProblems.Request,
  GetProblems.Response
>('getProblems');

export const getProblemById = FirebaseFunction<
  GetProblemById.Request,
  GetProblemById.Response
>('getProblemById');

export const addProblem = FirebaseFunction<
  AddProblem.Request,
  AddProblem.Response
>('addProblem');

export const updateProblemById = FirebaseFunction<
  UpdateProblemById.Request,
  UpdateProblemById.Response
>('updateProblemById');

export const getSolutionById = FirebaseFunction<
  GetSolutionById.Request,
  GetSolutionById.Response
>('getSolutionById');

export const updateSolutionById = FirebaseFunction<
  UpdateSolutionById.Request,
  UpdateSolutionById.Response
>('updateSolutionById');

export const getSubmissionsById = FirebaseFunction<
  GetSubmissionsById.Request,
  GetSubmissionsById.Response
>('getSubmissionsById');

export const updateSubmissionById = FirebaseFunction<
  UpdateSubmissionById.Request,
  UpdateSubmissionById.Response
>('updateSubmissionById');

export const createSubmission = FirebaseFunction<
  CreateSubmission.Request,
  CreateSubmission.Response
>('createSubmission');

export const deleteSubmissionById = FirebaseFunction<
  DeleteSubmissionById.Request,
  DeleteSubmissionById.Response
>('deleteSubmissionById');
