import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';

import {
  createSubmission,
  updateSubmissionById,
} from '../firebase/FirebaseFunction';

import {
  contentUpdateBarMessageState,
  contentUpdateBarOpenState,
  currentProblemIdState,
  currentSubmissionsState,
  editingSubmissionState,
  submissionEditDialogOpenState,
} from '../Atoms';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function EditSubmissionDialog() {
  const theme = useTheme();
  const problemId = useRecoilValue(currentProblemIdState);
  const [editDialogOpen, setEditDialogOpen] = useRecoilState(
    submissionEditDialogOpenState,
  );
  const setBarOpen = useSetRecoilState(contentUpdateBarOpenState);
  const setBarMessage = useSetRecoilState(contentUpdateBarMessageState);
  const [editingSubmission, setEditingSubmission] = useRecoilState(
    editingSubmissionState,
  );
  const [content, setContent] = useState<string>(
    editingSubmission?.submission?.content || '',
  );
  const resetCurrentSubmissions = useResetRecoilState(currentSubmissionsState);
  const handleClose = () => {
    // clean up currentEditingSubmission
    setEditingSubmission(undefined);
    setEditDialogOpen(false);
  };

  const handleSave = async () => {
    if (!editingSubmission) {
      throw new Error('error when saving empty submission');
    }
    const { id } = editingSubmission;
    let { submission } = editingSubmission;
    // update submission content if changed
    if (content && problemId) {
      submission = { ...submission, problemId, content };
    }
    try {
      if (id) {
        // update if id exists
        await updateSubmissionById({ id, submission });
      } else if (submission) {
        // create if id not exists
        await createSubmission({ submission });
      }
    } catch (err) {
      // failed save
      setBarMessage('Error happened in saving the content');
      setBarOpen(true);
    } finally {
      // invalidate current submissions
      resetCurrentSubmissions();
      // clean up currentEditingSubmission
      setEditingSubmission(undefined);
      // successful save
      setBarMessage('Successfully saved!');
      setBarOpen(true);
    }
    setEditDialogOpen(false);
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newContent = e.target.value;
    setContent(() => newContent);
  };

  return (
    <Dialog
      fullScreen
      open={editDialogOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              marginLeft: theme.spacing(2),
              flex: 1,
            }}
          >
            Add / Edit Problem
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              handleSave().catch(() =>
                console.error('Failed to save the submission.'),
              );
            }}
          >
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <TextField
            id="outlined-multiline-static"
            label="Submission Content"
            multiline
            style={{ margin: 8 }}
            rows={20}
            fullWidth
            variant="outlined"
            defaultValue={editingSubmission?.submission.content}
            onChange={handleContentChange}
          />
          <ReactMarkdown>{content || ''}</ReactMarkdown>
        </div>
      </Box>
    </Dialog>
  );
}
