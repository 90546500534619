import { Helmet } from 'react-helmet';
import { Route, Routes, useRoutes } from 'react-router-dom';

import { Analytics } from './components/Analytics';
import { CheckoutBar } from './components/CheckoutBar';
import { ContentUpdateBar } from './components/ContentUpdateBar';
import { NavigationBar } from './components/NavigationBar';
import { NavigationDrawer } from './components/NavigationDrawer';
import { Billing } from './pages/Billing';
import { Checkout } from './pages/Checkout';
import { Home } from './pages/Home';
import { Jobs } from './pages/Jobs';
import { LoggedInPage } from './pages/LoggedInPage';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { AlgoLibrary } from './pages/algolibrary/AlgoLibrary';
import { Problem } from './pages/algolibrary/Problem';
import { Solution } from './pages/algolibrary/Solution';
import { Submissions } from './pages/algolibrary/Submissions';
import { AlgoTogether } from './pages/programs/algotogether/AlgoTogether';
import { AlgoTogetherPreview } from './pages/programs/algotogether/AlgoTogetherPreview';
import { AlgoTogetherSuccessStories } from './pages/programs/algotogether/AlgoTogetherSuccessStories';
import { AlgoTogetherSummary } from './pages/programs/algotogether/AlgoTogetherSummary';
import { AlgoTogetherTestimonials } from './pages/programs/algotogether/AlgoTogetherTestimonials';
import { AlgoTogetherPreviewTabs } from './tabs/programs/algotogether/AlgoTogetherPreviewTabs';
import { AlgoTogetherTabs } from './tabs/programs/algotogether/AlgoTogetherTabs';

// There are 3 sets of routes that operate independently in this component: title, tabs, page.
// Having all routes in the same component makes it easier to add/remove routes.
export function AppRoutes() {
  const title = useRoutes([
    {
      path: '/billing',
      element: 'Billing',
    },
    {
      path: '/programs/algotogether/*',
      element: 'AlgoTogether',
    },
    {
      path: '/problems/*',
      element: 'AlgoLibrary',
    },
    {
      path: '/*',
      element: 'Tech Career',
    },
  ]);

  return (
    <>
      <Helmet titleTemplate="%s | Tech Career" defaultTitle="Tech Career" />
      <Analytics />
      <NavigationBar title={title}>
        <Routes>
          <Route
            path="/programs/algotogether/preview"
            element={<AlgoTogetherPreviewTabs />}
          />
          <Route
            path="/programs/algotogether/*"
            element={<AlgoTogetherTabs />}
          />
        </Routes>
      </NavigationBar>
      <NavigationDrawer />
      <CheckoutBar />
      <ContentUpdateBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/billing"
          element={
            <LoggedInPage>
              <Billing />
            </LoggedInPage>
          }
        />
        <Route
          path="/checkout"
          element={
            <LoggedInPage>
              <Checkout />
            </LoggedInPage>
          }
        />
        <Route path="/programs/algotogether" element={<AlgoTogether />}>
          <Route index element={<AlgoTogetherSummary />} />
          <Route path="testimonials" element={<AlgoTogetherTestimonials />} />
          <Route path="stories" element={<AlgoTogetherSuccessStories />} />
          <Route path="preview" element={<AlgoTogetherPreview />} />
        </Route>
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/problems" element={<AlgoLibrary />} />
        <Route path="/problems/:id/problem" element={<Problem />} />
        <Route path="/problems/:id/solution" element={<Solution />} />
        <Route path="/problems/:id/submissions" element={<Submissions />} />
        <Route element={<NotFound />} />
      </Routes>
    </>
  );
}
