import { Helmet } from 'react-helmet';

export function AlgoTogetherTestimonials() {
  return (
    <>
      <Helmet>
        <title>AlgoTogether Testimonials</title>
        <link
          rel="canonical"
          href="https://techcareer.io/programs/algotogether/testimonials"
        />
      </Helmet>
      <h2>Leaf (2020Q2 Program)</h2>
      <blockquote>
        <p>
          The most important lesson learned in the program for me, is knowing
          how critical or difficult is to deliver my thinking to interviewers. I
          didn’t realize until I started actually speak in front of others, that
          it’s one thing to figure out how to solve the problem and it’s totally
          another thing to tell the story in a simple way. Coaches helped me
          understand that, once you can speak your mind out as you think it
          through, you will be confident in any interview.
        </p>
      </blockquote>
      <h2>Huabo (2020Q2 Program)</h2>
      <blockquote>
        <p>
          I&#8217;ve been teaching college level data structures class for a few
          years and not long ago, I saw a subtle change: students grow more
          interests in big tech companies and their technique interview
          processes, while no direct answers can be found from classic textbook.
          Tech interview process is like an adventure and students simply want
          to know something about it. And I want to know more about it.
        </p>
        <p>
          I started learning about tech interviews by creating a LeetCode
          account and solving simple questions that are highly related to basic
          data structures like stack and binary tree. The process was fairly
          slow, as all I needed at that moment was to bring some interview
          questions as fun sour patches to my existing teaching plan. However, I
          do wish an opportunity to push my understanding of interview questions
          to the next level, thus this will benefit myself and my students
          further.
        </p>
        <p>
          The AlgoTogether training camp gave me this opportunity. I attended
          the second term of the camp, where the length was eight weeks and the
          total number of selected questions was more than 100 (including
          optional questions). The training&#8217;s topics were comprehensive,
          helping me to practice more advanced data structures like interval
          trees. The camp brought me another valuable piece by performing and
          observing mock interviews from veteran software engineers.
        </p>
        <p>I am thankful for AlgoTogether. </p>
      </blockquote>
      <h2>How can I become a successful student like them?</h2>
      <p>
        Please pick a program from below and click the &quot;purchase&quot;
        button to start. We will contact you through email after successful
        payment.
      </p>
    </>
  );
}
