import React, { useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/material/styles';

import {
  HumanLanguage,
  HumanLanguageCode,
  ProgrammingLanguage,
  ProgrammingLanguageName,
} from '../const/Languages';

export function LanguageTabs(
  props: React.ComponentProps<typeof Tabs> & {
    children?: React.FunctionComponentElement<LanguageTabPanelProps>[];
  },
) {
  const theme = useTheme();
  const languages: string[] = useMemo(() => {
    return (
      React.Children.map(props.children, (child) => {
        if (
          !React.isValidElement(child) ||
          child.props.language === undefined
        ) {
          return null;
        } else {
          return child.props.language as string;
        }
      })?.filter((language, index, languages) => {
        return language !== undefined && languages.indexOf(language) === index;
      }) || []
    );
  }, [props.children]);
  const [language, setLanguage] = useState<string | false>(
    languages[0] || false,
  );

  return (
    <Paper>
      <Tabs
        {...props}
        variant="fullWidth"
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, value: string) => {
          setLanguage(value);
        }}
        value={language}
        sx={{
          borderBottom: `1px solid ${theme.palette.divider}`,
        }}
      >
        {languages.map((language) => {
          let label = language;
          if (language in HumanLanguage) {
            label = HumanLanguage[language as HumanLanguageCode];
          }
          if (language in ProgrammingLanguage) {
            label = ProgrammingLanguage[language as ProgrammingLanguageName];
          }
          return <Tab value={language} label={label} key={language} />;
        })}
      </Tabs>
      {React.Children.map(props.children, (child) => {
        if (!React.isValidElement(child) || child.props.language !== language) {
          return null;
        } else {
          return child;
        }
      })}
    </Paper>
  );
}

export type LanguageTabPanelProps = React.ComponentProps<typeof Box> & {
  language: HumanLanguageCode | ProgrammingLanguageName;
};

export function LanguageTabPanel(props: LanguageTabPanelProps) {
  return <Box {...props}>{props.children}</Box>;
}
