import { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { useSetRecoilState } from 'recoil';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';

import { problemEditDialogOpenState } from '../../Atoms';
import { AddProblemDialog } from '../../components/AddProblemDialog';
import {
  AllProblemsFilterButton,
  FilterButton,
} from '../../components/FilterButton';
import { Loading } from '../../components/Loading';
import { ProblemList } from '../../components/ProblemList';
import { ALL_PROBLEM_FILTERS } from '../../const/AlgoLibraryConst';

export function AlgoLibrary() {
  const theme = useTheme();
  const setOpen = useSetRecoilState(problemEditDialogOpenState);

  return (
    <Container
      fixed
      sx={{
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
      }}
    >
      <Helmet>
        <title>AlgoLibrary</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item sm={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
            onClick={() => {
              setOpen(true);
            }}
          >
            Add Problem
          </Button>
          <AddProblemDialog />
          <ButtonGroup
            orientation="vertical"
            color="primary"
            sx={{
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          >
            <AllProblemsFilterButton />
            {ALL_PROBLEM_FILTERS.map((filter) => {
              return <FilterButton filter={filter} key={filter} />;
            })}
          </ButtonGroup>
        </Grid>
        <Grid item sm={10}>
          <Suspense fallback={<Loading text="Loading..." />}>
            <ProblemList />
          </Suspense>
        </Grid>
      </Grid>
    </Container>
  );
}
