import React from 'react';
import { Link } from 'react-router-dom';

import Tab from '@mui/material/Tab';

export function LinkTab(
  props: React.ComponentProps<typeof Link> & React.ComponentProps<typeof Tab>,
) {
  return <Tab {...props} component={Link} />;
}
