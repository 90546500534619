export enum HumanLanguage {
  en = 'English',
  zh = '中文',
}

export type HumanLanguageCode = keyof typeof HumanLanguage;

export enum ProgrammingLanguage {
  JavaScript = 'JavaScript',
  Python = 'Python',
}

export type ProgrammingLanguageName = keyof typeof ProgrammingLanguage;
