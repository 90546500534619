import { Helmet } from 'react-helmet';

export function AlgoTogetherSummary() {
  return (
    <>
      <Helmet>
        <title>AlgoTogether</title>
        <link
          rel="canonical"
          href="https://techcareer.io/programs/algotogether"
        />
      </Helmet>
      <h2>What is this program?</h2>
      <p>
        AlgoTogether is an algorithmic problem study group with a coach. The
        program focuses on all necessary skills for coding interviews:
        problem-solving, coding, debugging, articulating solutions, taking
        feedback. The coach leads the meetings and mock interviews and makes
        sure that students learn these skills in a way that they can reapply to
        new problems in real interviews.
      </p>
      <h2>What is the value of this program?</h2>
      <ol>
        <li>
          <strong>Understand how an interviewer evaluates you.</strong> You are
          evaluated beyond correctness and optimality. If a company only
          evaluates these two it will replace human interviewers with LeetCode
          to save money. You should learn what’s missing beyond your LeetCode
          practice.
        </li>
        <li>
          <strong>Practice like you are in an interview.</strong> You will
          practice articulating your solution to an interviewer and taking hint
          or feedback from an interviewer. That’s what you don’t experience if
          you simply practice with LeetCode.
        </li>
        <li>
          <strong>Hold yourself accountable.</strong> Are you willing to commit
          to finishing a certain amount of problems within a specific time
          frame? If you can commit, we will hold you accountable and prevent you
          from slacking.
        </li>
      </ol>
      <h2>Who is this program for?</h2>
      <p>
        People who are highly committed to getting a software engineer job at
        one of the well-established tech companies. It’s best for people who are
        seeking a software engineer job for the first time, for example,{' '}
        <strong>newly graduated students</strong>. It’s also good for{' '}
        <strong>experienced software engineers</strong> who haven’t done
        interview preparation for more than a year.
      </p>
      <h2>What is the structure of the program?</h2>
      <p>
        <strong>
          The program is 9-week long. We meet in Zoom 3 times a week and
          practice together offline through other days.
        </strong>
      </p>
      <p>
        Each week the coach picks a theme (e.g. dynamic programming) and assigns
        10+ problems within this theme. You have one week to work on them. Then
        all students submit their solutions, share their thinking processes,
        vote on other students’ sharing, practice presenting solutions, and get
        mock interviews. The coach will provide support and feedback throughout
        the whole process.
      </p>
      <p>
        We meet on Wednesday, Saturday, and Sunday 7 pm (Pacific Time) in Zoom
        to have lectures, office hours, solution presentations, and mock
        interviews. We convene on Slack on the other days at 7 pm (Pacific Time)
        to discuss a focus topic.
      </p>
      <h2>Who are the coaches?</h2>
      <ul>
        <li>
          <strong>Wilson</strong>: ACM/ICPC medalist. Experienced at coaching
          students for competitions. Experienced interviewer at a FANG company.
        </li>
        <li>
          <strong>Cat</strong>: Programming competition experience from
          elementary school through high school. Interviewed ~200 candidates and
          trained 50 new interviewers at a FANG company.
        </li>
        <li>
          <strong>Michael</strong>: Experienced interviewer at a FANG company.
        </li>
      </ul>
      <p>
        We not only have impressive coaches but also awesome coaching
        assistants:
      </p>
      <ul>
        <li>
          <strong>Leaf</strong>: AlgoTogether top alumnus. Experienced of
          successful career pivot into Software Engineer.
        </li>
        <li>
          <strong>Tiger</strong>: Computer Science Ph.D candidate. Interned at a
          FANG company.
        </li>
      </ul>
      <h2>Can I get a free trial?</h2>
      <p>
        We provide <strong>free study materials</strong> instead of a free
        trial. You will get a taste of how our program is like from the free
        study materials. Click the &quot;free study materials&quot; button at
        the bottom of the page to start.
      </p>
      <p>
        If you have questions after checking out the free study materials you
        can icon us by clicking the &quot;email inquiry&quot; button at the
        bottom of the page. We will answer your email as quickly as possible.
      </p>
      <h2>How do I sign up and pay?</h2>
      <p>
        Please pick a program from below and click the &quot;purchase&quot;
        button to start. We will contact you through email after successful
        payment.
      </p>
    </>
  );
}
