import { useLocation } from 'react-router-dom';

import Tabs from '@mui/material/Tabs';

import { LinkTab } from '../../../components/LinkTab';

export function AlgoTogetherPreviewTabs() {
  const location = useLocation();
  return (
    <Tabs
      indicatorColor="primary"
      textColor="inherit"
      variant="fullWidth"
      value={location.pathname}
    >
      <LinkTab
        label="Overview"
        to="/programs/algotogether"
        value="/programs/algotogether"
        key="/programs/algotogether"
      />
      <LinkTab
        label="Testimonials"
        to="/programs/algotogether/testimonials"
        value="/programs/algotogether/testimonials"
        key="/programs/algotogether/testimonials"
      />
      <LinkTab
        label="Success Stories"
        to="/programs/algotogether/stories"
        value="/programs/algotogether/stories"
        key="/programs/algotogether/stories"
      />
      <LinkTab
        label="Free Study Materials"
        to="/programs/algotogether/preview"
        value="/programs/algotogether/preview"
        key="/programs/algotogether/preview"
      />
    </Tabs>
  );
}
