/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DefaultValue, atom, selector } from 'recoil';

import {
  getProblemById,
  getProblems,
  getSolutionById,
  getSubmissionsById,
} from './firebase/FirebaseFunction';

import { AlgoLibrary } from '../functions/src/schemas/AlgoLibrary';
import { ALL_PROBLEM_FILTERS } from './const/AlgoLibraryConst';

export const navigationDrawerOpenState = atom({
  key: 'navigationDrawerOpenState',
  default: false,
});

export const jobsDataValue = selector({
  key: 'jobsDataValue',
  get: async () => {
    const { data } = await import('@techcareerio/tech-jobs');
    return data;
  },
});

/* 
  an abitrary trigger used to force a selector to update the state 
  by having the selector calling 'get(trigger)' in selector's get method, 
  and calling 'set(trigger)' in selector's set method
*/
export const trigger = atom<number>({
  key: 'trigger',
  default: 0,
});

// TODO: #967 split Atoms.ts into smaller files dedicated to different kinds of atoms

// Problem

/*
  allProblemsCache:
  { 
    "3kjqrkwadf_4q" : { 
      "content" : "xxxxxxx", 
      "title" : "xxxxxxx",
      ...
    }, 
    ...
  }
*/
let allProblemsFetched = false;
let allProblemsCache: { [key: string]: AlgoLibrary.Problem } = {};

export const currentProblemIdState = atom<string | null | undefined>({
  key: 'currentProblemIdState',
  default: undefined,
});

export const allProblemsState = selector<{
  [key: string]: AlgoLibrary.Problem;
}>({
  key: 'allProblemsState',
  get: async ({ get }) => {
    const currentProblemId = get(currentProblemIdState);
    get(trigger);
    // in unknown page
    if (currentProblemId === undefined) {
    }
    // in problem list page
    else if (currentProblemId === null) {
      if (!allProblemsFetched) {
        try {
          // async fetch all problems
          const response = await getProblems();
          // cache the result in allProblemsCache
          response.data.forEach((e) => {
            if (e.id) {
              allProblemsCache = Object.assign(
                { [e.id]: e.problem },
                allProblemsCache,
              );
            }
          });
          // mark all problems fetched
          allProblemsFetched = true;
        } catch (err) {
          console.log(err);
        }
      }
    }
    // in problem page
    else {
      // current problem not fetched
      if (!allProblemsCache[currentProblemId]) {
        try {
          // async fetch current problem
          const response = await getProblemById({ id: currentProblemId });
          // cache the result in allProblemsCache
          allProblemsCache = Object.assign(
            { [currentProblemId]: { ...response.data } },
            allProblemsCache,
          );
        } catch (err) {
          console.log(err);
        }
      }
    }
    return allProblemsCache;
  },
  set: ({ set }, newValue) => {
    allProblemsCache = newValue instanceof DefaultValue ? {} : newValue;
    set(trigger, (v: number) => v + 1);
  },
});

export const currentFilteredProblemsValue = selector({
  key: 'currentFilteredProblemsValue',
  get: ({ get }) => {
    // get problems from 'allProblemsState' whose label is included in currentProblemFilters
    const currentProblemFilters = get(currentProblemFiltersState);
    const allProblems = get(allProblemsState);
    const filteredProblems: { [id: string]: AlgoLibrary.Problem } = {};
    Object.entries(allProblems).forEach(([id, problem]) => {
      if (problem.label) {
        if (currentProblemFilters.includes(problem.label)) {
          filteredProblems[id] = problem;
        }
      }
    });
    return filteredProblems;
  },
});

export const currentProblemFiltersState = atom<string[]>({
  key: 'currentProblemFilters',
  default: ALL_PROBLEM_FILTERS,
});

export const problemEditDialogOpenState = atom({
  key: 'problemEditDialogOpenState',
  default: false,
});

export const currentProblemState = selector<AlgoLibrary.Problem>({
  key: 'currentProblemState',
  get: ({ get }) => {
    const currentProblemId = get(currentProblemIdState);
    const allProblems = get(allProblemsState);
    return currentProblemId
      ? allProblems[currentProblemId]
      : { title: '', content: '' };
  },
  set: ({ get, set }, newValue) => {
    const currentProblemId = get(currentProblemIdState);
    if (currentProblemId) {
      const newAllProblemsState = Object.fromEntries(
        Object.entries(allProblemsCache).map(([key, value]) => {
          /* 
            if reset is called, remove current problem from allProblemsState
            if set is called, update current problem with newValue
          */
          return newValue instanceof DefaultValue
            ? key !== currentProblemId
              ? [key, value]
              : []
            : key !== currentProblemId
            ? [key, value]
            : [key, newValue];
        }),
      );
      set(allProblemsState, newAllProblemsState);
    }
    set(trigger, (v: number) => v + 1);
  },
});

// Solution

/*
  allSolutionCache:
  { 
    "3kjqrkwadf_4q" : { 
      content: "yyyyyyyy", 
      ...
    }, 
    ...
  }
*/
let allSolutionCache: { [key: string]: AlgoLibrary.Solution } = {};

export const currentSolutionState = selector<AlgoLibrary.Solution>({
  key: 'currentSolutionState',
  get: async ({ get }) => {
    const currentProblemId = get(currentProblemIdState);
    get(trigger);
    if (
      currentProblemId &&
      (!allSolutionCache || !allSolutionCache[currentProblemId])
    ) {
      try {
        const solution = await getSolutionById({ id: currentProblemId });
        allSolutionCache = Object.assign(
          { [currentProblemId]: solution.data },
          allSolutionCache,
        );
      } catch (err) {
        console.log(err);
      }
    }
    return currentProblemId
      ? allSolutionCache[currentProblemId]
      : { content: '' };
  },
  set: ({ get, set }, newValue) => {
    const currentProblemId = get(currentProblemIdState);
    if (currentProblemId) {
      const newAllSolutionCache = Object.fromEntries(
        Object.entries(allSolutionCache).map(([key, value]) => {
          /* 
            if reset is called, remove current solution from allSolutionCache
            if set is called, update current solution with newValue
          */
          return newValue instanceof DefaultValue
            ? key !== currentProblemId
              ? [key, value]
              : []
            : key !== currentProblemId
            ? [key, value]
            : [key, newValue];
        }),
      );
      allSolutionCache = newAllSolutionCache;
    }
    set(trigger, (v: number) => v + 1);
  },
});

export const solutionEditDialogOpenState = atom({
  key: 'solutionEditDialogOpenState',
  default: false,
});

// Submissions

/*
  allSubmissionsCache:
  { 
    "3kjqrkwadf_4q" : {
      problemId: "2q34asd_234" 
      content: "yyyyyyyy", 
      ...
    }, 
    ...
  }
*/
let allSubmissionsCache: { [key: string]: AlgoLibrary.Submission } = {};

export const allSubmissionsState = selector<{
  [key: string]: AlgoLibrary.Submission;
}>({
  key: 'allSubmissionsState',
  get: async ({ get }) => {
    const currentProblemId = get(currentProblemIdState);
    get(trigger);
    if (currentProblemId) {
      // in submissions page
      if (!ifSubmissionsofProblemFetched(currentProblemId)) {
        // submissions of current problem not fetched
        try {
          const submissionsOfProblem = await getSubmissionsById({
            id: currentProblemId,
          });
          // cache the result in allProblemsCache
          submissionsOfProblem.data.forEach((e) => {
            if (e.id) {
              allSubmissionsCache = Object.assign(
                { [e.id]: e.submission },
                allSubmissionsCache,
              );
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
    } else {
      /* TODO: #966 in all submission page
        currentProblemId === null
      */
    }
    return allSubmissionsCache;
  },
  set: ({ set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      // TODO: if reset
    } else {
      allSubmissionsCache = newValue;
    }
    set(trigger, (v: number) => v + 1);
  },
});

export const currentSubmissionsState = selector<{
  [key: string]: AlgoLibrary.Submission;
}>({
  key: 'currentSubmissionsState',
  get: ({ get }) => {
    const currentProblemId = get(currentProblemIdState);
    const allSubmissions = get(allSubmissionsState);
    if (currentProblemId) {
      return Object.fromEntries(
        Object.entries(allSubmissions).filter(
          ([_key, value]) => value?.problemId === currentProblemId,
        ),
      );
    } else {
      return {};
    }
  },
  set: ({ get, set }, newValue) => {
    const currentProblemId = get(currentProblemIdState);
    if (currentProblemId) {
      const newAllSubmissionsState = Object.fromEntries(
        Object.entries(allSubmissionsCache).map(([key, value]) => {
          /* 
            if reset is called, remove current submissions from allSubmissionsState
            if set is called, update current submissions with newValue
          */
          return newValue instanceof DefaultValue
            ? value?.problemId !== currentProblemId
              ? [key, value]
              : []
            : value?.problemId !== currentProblemId
            ? [key, value]
            : [key, newValue[key]];
        }),
      );
      set(allSubmissionsState, newAllSubmissionsState);
    }
    set(trigger, (v: number) => v + 1);
  },
});

const ifSubmissionsofProblemFetched = (problemId: string) => {
  return Object.entries(allSubmissionsCache).some(
    ([_key, value]) => value?.problemId === problemId,
  );
};

export const submissionEditDialogOpenState = atom({
  key: 'submissionEditDialogOpenState',
  default: false,
});

export const editingSubmissionState = atom<
  | {
      id?: string;
      submission: AlgoLibrary.Submission;
    }
  | undefined
>({
  key: 'editingSubmissionState',
  default: undefined,
});

export const contentUpdateBarOpenState = atom({
  key: 'contentUpdateBarOpenState',
  default: false,
});

export const contentUpdateBarMessageState = atom<string | undefined>({
  key: 'contentUpdateBarMessageState',
  default: undefined,
});
