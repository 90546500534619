import { Outlet } from 'react-router-dom';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import EmailIcon from '@mui/icons-material/Email';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';

import { FirebaseAnalytics } from '../../../firebase/FirebaseAnalytics';
import { logEvent } from 'firebase/analytics';

import { CheckoutCard } from '../../../components/CheckoutCard';

export function AlgoTogether() {
  const theme = useTheme();
  return (
    <Container
      fixed
      sx={{
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
      }}
    >
      <Grid container direction="column" justifyContent="center" spacing={2}>
        <Grid item>
          <Outlet />
        </Grid>
        <Grid item>
          <CheckoutCard item="algotogether202101">
            <Typography variant="h5" component="p" gutterBottom>
              January - March, 2021
            </Typography>
          </CheckoutCard>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              startIcon={<MenuBookIcon />}
              href="https://techcareer.typeform.com/to/IKKYnObZ"
              onClick={() => {
                logEvent(FirebaseAnalytics, 'generate_lead', {});
              }}
            >
              Free Study Materials
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<QuestionAnswerIcon />}
              href="mailto:algotogether@techcareer.io?subject=AlgoTogether%20Inquiry"
            >
              Email Inquiry
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              startIcon={<EmailIcon />}
              href="https://techcareer.typeform.com/to/bKA04rnU"
              onClick={() => {
                logEvent(FirebaseAnalytics, 'generate_lead', {});
              }}
            >
              Follow Updates
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
