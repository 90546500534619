import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { FirebaseAnalytics } from '../firebase/FirebaseAnalytics';
import { getStripeCheckoutSession } from '../firebase/FirebaseFunction';
import { logEvent } from 'firebase/analytics';

import { History } from '../components/HistoryState';
import { Loading } from '../components/Loading';
import useStripe from '../hooks/useStripe';

export function Checkout() {
  const location = useLocation();
  const stripe = useStripe();

  useEffect(() => {
    logEvent(FirebaseAnalytics, 'begin_checkout', {
      ...(location.state as History.State)?.eventParams,
    });
  }, [location.state]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    (async () => {
      const searchParams = new URLSearchParams(location.search);
      const item = searchParams.get('item');
      const path = searchParams.get('path');
      if (item === null) {
        console.error('Cannot checkout without an item.');
        return;
      }
      const response = await getStripeCheckoutSession({
        item,
        ...(path === null ? undefined : { path }),
      });
      logEvent(FirebaseAnalytics, 'checkout_progress', {
        ...(location.state as History.State)?.eventParams,
      });
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });
      if (error) {
        console.error(error);
      }
    })().catch(() => console.error('Failed to fetch checkout session URL.'));
  }, [location.state, location, stripe]);

  return (
    <>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      <Loading text="Preparing your bill..." />
    </>
  );
}
