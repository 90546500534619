import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useRecoilState, useResetRecoilState } from 'recoil';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';

import CloseIcon from '@mui/icons-material/Close';

import { updateSolutionById } from '../firebase/FirebaseFunction';

import { AlgoLibrary } from '../../functions/src/schemas/AlgoLibrary';
import { currentSolutionState, solutionEditDialogOpenState } from '../Atoms';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function EditSolutionDialog({
  data,
}: {
  data: { id: string; solution: AlgoLibrary.Solution };
}) {
  const theme = useTheme();
  const [open, setOpen] = useRecoilState(solutionEditDialogOpenState);
  const [newSolution, setNewSolution] = useState(data);
  const handleClose = () => {
    setOpen(false);
  };
  const resetCurrentSolution = useResetRecoilState(currentSolutionState);

  const handleSave = async () => {
    try {
      await updateSolutionById(newSolution);
    } catch (err) {
      console.log(err);
    }
    setOpen(false);
    resetCurrentSolution();
  };

  const handleContentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setNewSolution((prevState) => ({
      ...prevState,
      solution: { ...prevState.solution, content: newValue },
    }));
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: 'relative',
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              marginLeft: theme.spacing(2),
              flex: 1,
            }}
          >
            Edit Solution
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              handleSave().catch(() =>
                console.error('Failed to save the solution.'),
              );
            }}
          >
            Save
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <div>
          <TextField
            id="outlined-multiline-static"
            label="Solution Content"
            multiline
            style={{ margin: 8 }}
            rows={20}
            fullWidth
            variant="outlined"
            defaultValue={newSolution.solution?.content}
            onChange={handleContentChange}
          />
          <ReactMarkdown>{newSolution.solution?.content}</ReactMarkdown>
        </div>
      </Box>
    </Dialog>
  );
}
