import React, { Suspense, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Link, useParams } from 'react-router-dom';
import { default as SyntaxHighlighter } from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { deleteSubmissionById } from '../../firebase/FirebaseFunction';

import { AlgoLibrary } from '../../../functions/src/schemas/AlgoLibrary';
import {
  currentProblemIdState,
  currentSubmissionsState,
  editingSubmissionState,
  submissionEditDialogOpenState,
} from '../../Atoms';
import { EditSubmissionDialog } from '../../components/EditSubmissionDialog';
import { Loading } from '../../components/Loading';

export function Submissions() {
  const { id } = useParams();
  if (id === undefined) {
    throw new Error('Submission component requires an id param');
  }
  return (
    <div>
      <Link to={`/problems/${id}/problem`}>Go To Problem</Link>
      <Link to={`/problems/${id}/solution`}>Go To Solution</Link>
      <Link to={`/problems/${id}/submissions`}>Go To Submissions</Link>
      <Link to={`/problems`}>Go Back to Problems</Link>
      <Suspense fallback={<Loading text="Loading..." />}>
        <SubmissionsList id={id} />
      </Suspense>
    </div>
  );
}

function SubmissionsList({ id }: { id: string }) {
  const [problemId, setProblemId] = useRecoilState(currentProblemIdState);
  // in submissions page, set problemIdState with value from path
  if (problemId === undefined || problemId === null || problemId !== id) {
    setProblemId(() => id);
  }

  // get submissions by problem id
  const currentSubmissions = useRecoilValue(currentSubmissionsState);

  // invalidate submissions of current problem
  const resetCurrentSubmissions = useResetRecoilState(currentSubmissionsState);

  // edit dialog
  const setEditDialogOpen = useSetRecoilState(submissionEditDialogOpenState);
  // handle select submission to edit
  const setEditingSubmission = useSetRecoilState(editingSubmissionState);
  // delete dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // handle select submission to delete
  const [submissionIdToBeDeleted, setSubmissionIdToBeDeleted] =
    useState<string>();

  const components = {
    code({
      inline,
      className,
      children,
      ...props
    }: {
      inline?: boolean;
      className?: string;
      children: React.ReactNode;
    }) {
      const match = /language-(\w+)/.exec(className || '');
      return !inline && match ? (
        <SyntaxHighlighter
          style={github}
          language={match[1]}
          PreTag="div"
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
      ) : (
        <code className={className} {...props} />
      );
    },
  };

  return (
    <div>
      <EditSubmissionDialog />
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Are you sure to delete this submission?</DialogTitle>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            No
          </Button>
          <Button
            onClick={() => {
              (async () => {
                if (!submissionIdToBeDeleted) {
                  return;
                }
                await deleteSubmissionById({
                  id: submissionIdToBeDeleted,
                });
                resetCurrentSubmissions();
                setDeleteDialogOpen(false);
              })().catch(() => console.error('Failed to save the submission.'));
            }}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        onClick={() => {
          setEditingSubmission({
            submission: { problemId: id, content: '' },
          });
          setEditDialogOpen(true);
        }}
        variant="contained"
        color="primary"
      >
        Add Submission
      </Button>
      <Grid>
        {Object.entries(currentSubmissions).map(
          ([id, submission]: [
            id: string,
            submission: AlgoLibrary.Submission,
          ]) => (
            <Accordion key={id}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{id}</Typography>
              </AccordionSummary>
              <AccordionDetails style={{ display: 'block' }}>
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setEditingSubmission({ id, submission });
                      setEditDialogOpen(true);
                    }}
                  >
                    Edit Submission
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setSubmissionIdToBeDeleted(id);
                      setDeleteDialogOpen(true);
                    }}
                  >
                    Delete Submission
                  </Button>
                </div>
                <Typography display="block">
                  problem id: {submission?.problemId}
                </Typography>
                <ReactMarkdown components={components}>
                  {submission?.content || ''}
                </ReactMarkdown>
              </AccordionDetails>
            </Accordion>
          ),
        )}
      </Grid>
    </div>
  );
}
