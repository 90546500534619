import { useEffect, useState } from 'react';

import { Auth } from '../firebase/Firebase';

const useFirebaseUser = () => {
  const [ready, setReady] = useState(false);
  const [user, setUser] = useState(Auth.currentUser);

  useEffect(() => {
    Auth.onAuthStateChanged(function (user) {
      setUser(user);
      setReady(true);
    });
  }, []);

  return { ready, user };
};

export default useFirebaseUser;
