import React, { useMemo } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import ScheduleIcon from '@mui/icons-material/Schedule';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import useCheckout from '../hooks/useCheckout';

type Props = {
  item: string;
  children: React.ReactNode;
};

export function CheckoutCard(props: Props) {
  const { ready, price, path, click } = useCheckout(props.item);
  const { disabled, buttonIcon, buttonText, descriptionText } = useMemo(() => {
    if (!ready || !price) {
      return {
        disabled: true,
        buttonIcon: <ShoppingCartIcon />,
        buttonText: 'Purchase',
        descriptionText: 'Checking price and availability...',
      };
    }
    const priceText = `${(price.unit_amount ?? 0 / 100).toFixed(
      2,
    )} ${price.currency.toUpperCase()}`;
    if (price.inventory?.type === 'finite') {
      if (price.inventory?.quantity > 0) {
        return {
          disabled: false,
          buttonIcon: <ShoppingCartIcon />,
          buttonText: 'Purchase',
          descriptionText: `${price.inventory?.quantity} spots left at ${priceText}.`,
        };
      } else {
        return {
          disabled: true,
          buttonIcon: <ShoppingCartIcon />,
          buttonText: 'Sold Out',
          descriptionText: '0 spots left.',
        };
      }
    }
    if (!price.active) {
      return {
        disabled: true,
        buttonIcon: <ScheduleIcon />,
        buttonText: 'Upcoming',
        descriptionText: 'Not available. Please check again later.',
      };
    }
    return {
      disabled: false,
      buttonIcon: <ShoppingCartIcon />,
      buttonText: 'Purchase',
      descriptionText: `Available at ${priceText}.`,
    };
  }, [ready, price]);

  return (
    <Card>
      <CardContent>
        {props.children}
        <Typography variant="body2" component="p" color="textSecondary">
          {descriptionText}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          variant="contained"
          startIcon={buttonIcon}
          href={path}
          disabled={disabled}
          onClick={click}
        >
          {buttonText}
        </Button>
      </CardActions>
    </Card>
  );
}
