import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Loading } from '../components/Loading';
import useFirebaseUser from '../hooks/useFirebaseUser';

export function LoggedInPage({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const location = useLocation();
  const { ready, user } = useFirebaseUser();
  const path = useMemo(() => {
    return `${location.pathname}${location.search}`;
  }, [location]);

  if (!ready) {
    return <Loading />;
  }

  if (user) {
    return children;
  }

  return <Navigate to={`/login?path=${encodeURIComponent(path)}`} />;
}
